<template>    
    <v-card class="pa-4" style="max-width: 800px; width: 800px; margin-bottom: 128px;">
        <div class="d-flex flex-column">
            <h1 class="text-h5 text-md-h4 mb-2 mb-sm-4">{{ surveyTitle }}</h1>
            <p class="text-justify line-height-2 line-height-sm-3 text-body-1 text-md-h6 font-weight-regular mb-4" style="white-space: pre-wrap;">{{ outroText }}</p>
            <div class="d-flex flex-sm-row flex-column align-center justify-end align-sm-end gap-4 flex-wrap">
                <v-btn color="primary" append-icon="mdi-arrow-left" @click="handleBackButton()" class="">{{ t('back') }}</v-btn>
                <v-btn color="primary" append-icon="mdi-flag-triangle" @click="showConfirmDialog = true;" class="">{{ t('end') }}</v-btn>
            </div>
        </div>
        <v-snackbar v-model="showSnackbar" color="red">{{ t('unexpected-error') }}</v-snackbar>
        <v-dialog v-model="showDialog" persistent content-class="d-flex justify-center align-center">
            <v-card class="pa-4" width="fit-content">
                <div class="text-h6 mb-4">
                    {{ t('required-items-remaining') }}
                </div>                
                <div class="d-flex justify-end">
                    <v-btn flat color="primary" @click="handleValidationError">{{t('allright')}}</v-btn>
                </div>            
            </v-card>
        </v-dialog>
        <v-dialog v-model="showConfirmDialog" persistent content-class="d-flex justify-center align-center">
            <v-card class="pa-4" width="fit-content">
                <div class="text-subtitle-1 text-sm-h6" :class="{'font-weight-bold':xs}">
                    {{ t('confirm-title') }}
                </div>
                <div class="text-sm-subtitle-1 text-subtitle-2">
                    {{t('confirm-message')}}
                </div>
                <div class="d-flex justify-end gap-3 mt-2">
                    <v-btn color="primary" @click="showConfirmDialog = false;">{{ t('cancel') }}</v-btn>
                    <v-btn color="primary" @click="handleEndButton">{{t('yes')}}</v-btn>
                </div>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { useDisplay } from 'vuetify';

const { t } = useI18n({useScope: 'local'});
const { xs } = useDisplay();
const props = defineProps({surveyTitle: {type: String, default: ''}, outroText: {type: String, default: ''}})
const emit = defineEmits(['back', 'validation-error', 'finish']);

const showSnackbar = ref(false);
const showDialog = ref(false);
const showConfirmDialog = ref(false);

const handleEndButton = function(){
    console.log('ENDED');

    axios.post('/participant/finish')
    .then(response => {
        if (response.data.error.has) {
            if (response.data.error.code == 'srvy:540') {
                showDialog.value = true;
            }
            else{
                showSnackbar.value = true;
                console.log('Fehler aufgetreten', response.data);
            }
        }
        else{
            emit('finish');
            console.log('No error')
        }
    })
}
const handleBackButton = function(){
    emit('back');
}

const handleValidationError = function(){
    showDialog.value = false;
    emit('validation-error');
}
</script>

<i18n>
    {
        "de": {
            "back": "Zurück zu den Fragen",
            "end": "Befragung abschließen",
            "cancel": "Abbrechen",
            "yes": "Ja",
            "allright": "Alles klar",
            "required-items-remaining": "Du hast noch nicht alle Pflichtfragen beantwortet!",
            "confirm-title": "Befragung wirklich abschließen?",
            "confirm-message": "Nach dem Abschließen kannst du deine Antworten nicht mehr ändern!",
            "unexpected-error": "Ein unerwarteter Fehler ist aufgetreten"
        },
        "en": {
            "back": "Bring me back",
            "end": "Finish survey",
            "cancel": "Cancel",
            "yes": "Yes",
            "allright": "Allright",
            "required-items-remaining": "You haven't answered all required questions yet!",
            "confirm-title": "Finish survey?",
            "confirm-message":"You can't change anymore answers after submitting!",
            "unexpected-error": "An unexpected error occured"
        }
    }
</i18n>