<template>
    <div class="d-flex h-100 w-100 justify-center align-center">  
        <v-card style="width: 600px" class="py-6 px-2 px-sm-6">
            <div class="d-flex flex-column align-start">
                <div class="text-h6 text-sm-h5 text-md-h4 mb-1">{{ t('heading') }}</div>
                <div class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis line-height-3 font-weight-regular mb-8">{{ t('info-text') }}</div>
                <v-form class="w-100 d-flex flex-column align-end" ref="form" @submit.prevent="submitForm">
                    <v-text-field @keydown.enter="handleEnterOnCodeInput" v-model="codeInput" 
                    :rules="codeRules" 
                    class="w-100" 
                    variant="outlined" 
                    :label="t('code-label')" 
                    ref="code-text-field" 
                    autofocus
                    @input="handleCodeInput"
                    :error-messages="codeTextFieldError"
                    :loading="codeTextFieldLoading"></v-text-field>
                    <v-btn class="align-self-end" color="primary" ref="submitButton" type="submit">{{ t('login-label') }}</v-btn>
                </v-form>
            </div>
        </v-card>      
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const codeInput = ref(null);
const submitButton = ref(null);
const codeTextFieldError = ref('');
const codeTextFieldLoading = ref(false);

const handleEnterOnCodeInput = () => {
    indicateButtonClick();
}

const indicateButtonClick = () => {
    submitButton.value.$el.dispatchEvent(new Event("mousedown"))
    submitButton.value.$el.dispatchEvent(new Event("mouseup"))
}

const form = ref(null);
const validateForm = async () => {
    const promise = await form.value.validate();
    return promise;
}

const submitForm = () => {
    validateForm().then(form => {
        codeTextFieldLoading.value = true;
        if (form.valid) {
            sendLoginRequest();
        }
    }).finally(()=>{
        codeTextFieldLoading.value = false;
    });
}

const codeRules = ref([
    value => {
        if (value) return true;
        return t('code-empty');
    },
    value => {
        if (/^[\d\w]{4}-[\d\w]{4}-[\d\w]{4}$/.test(value)) return true;
        console.log('value');
        return t('code-invalid');
    }
])

const sendLoginRequest = function(){
    axios.post('/participant/login', {access_code: codeInput.value})
    .then(response => handleLoginResponse(response))
}

const handleLoginResponse = function(response){
    if (response.data.error.has) {
        codeTextFieldError.value = t('error-codes.'+response.data.error.code, t('code-invalid'));
    }
    else{
        codeTextFieldError.value = '';
        router.push({name: 'survey'});
        emit('login', response.data);
    }
}

const handleCodeInput = function(input){
    codeTextFieldError.value = '';
}

const emit = defineEmits(['login']);

onMounted(()=>{
    if (route.params.code) {
        codeInput.value = route.params.code;
        sendLoginRequest();
    }
});
</script>

<i18n>
{
    "de": {
        "code-invalid": "Dieser Zugangscode ist ungültig",
        "code-empty": "Bitte geben Sie einen Zugangscode ein",
        "code-label": "Zugangscode",
        "login-label": "Anmelden",
        "heading": "Anmeldung zur Befragung",
        "info-text": "Um an einer Befragung teilzunehmen, geben Sie bitte Ihren Zugangscode ein.",
        "error-codes": {
            "auth:100": "Dieser Zugangscode ist leider ungültig : (",
            "auth:133": "Es ist ein außergewöhnliches Problem aufgetreten",
            "auth:160": "Du hast diese Befragung bereits abgeschlossen",
            "auth:180": "Diese Befragung ist gerade nicht aktiv : (",
            "auth:181": "Diese Befragung ist leider schon vorbei : ("
        }
    },
    "en": {
        "code-invalid": "Invalid code",
        "code-empty": "Please enter a code",
        "code-label": "Survey Code",
        "login-label": "Login",
        "heading": "Survey Login",
        "info-text": "To participate in a survey, you have to enter your survey code.",
        "error-codes": {
            "auth:100": "This code is invalid : (",
            "auth:133": "Something just went really wrong",
            "auth:160": "You already finished this survey",
            "auth:180": "The survey is currently inactive : (",
            "auth:181": "The survey is already over : ("
        }
    }
}
</i18n>