<template>    
    <v-card class="pa-4" style="max-width: 800px; width: 800px; margin-bottom: 128px;">
        <div class="d-flex flex-column">
            <h1 class="text-h5 text-md-h4 mb-2 mb-sm-4">{{ surveyTitle }}</h1>
            <p class="text-justify line-height-2 line-height-sm-3 text-body-1 text-md-h6 font-weight-regular mb-4" style="white-space: pre-wrap;">{{ introText }}</p>
            <v-btn color="primary" @click="handleStartButton" class="align-self-end">{{ t('start') }}</v-btn>
        </div>
    </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n({useScope: 'local'});
const props = defineProps({surveyTitle: {type: String, default: ''}, introText: {type: String, default: ''}})
const emit = defineEmits(['start']);

const handleStartButton = function(){
    emit('start');
}
</script>

<i18n>
    {
        "de": {
            "start": "Starten"
        },
        "en": {
            "start": "Start"
        }
    }
</i18n>