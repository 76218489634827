<template>
    <v-app>
        <v-app-bar class="bg-secondary border-b-2 border-primary" :height="80">
            <!-- <v-app-bar-title>EcoAsk</v-app-bar-title> -->
            <div class="d-flex w-auto flex-grow-1 flex-shrink-1 pl-2 pl-sm-4">
                <v-img 
                @click="navigateToHome"
                max-width="260"
                max-height="65"
                src="img/miscellaneous/ecoprotec_logo_white.png"></v-img> 
            </div>
            <v-spacer></v-spacer>  
            <v-btn icon color="white">
                <v-icon>mdi-menu</v-icon>
                <v-menu activator="parent">        
                    <v-list>
                        <!-- @click="sendLogoutRequest" -->
                        <v-list-item v-if="!!user.surveyCode" :link="true" @click="logoutDialog = true">
                            <div class="d-flex align-items gap-2">
                                <v-icon icon="mdi-logout"></v-icon>
                                <v-list-item-title>{{ t('logout') }}</v-list-item-title>                                
                            </div>                            
                        </v-list-item>
                        <v-list-item :link="true">
                            <!-- <div class="d-flex align-center gap-2"> -->
                            <div class="d-flex align-items gap-2">
                                <v-icon icon="mdi-earth"></v-icon>
                                <v-list-item-title>{{ t('language') }}</v-list-item-title>                                
                            </div>
                            <!-- </div> -->
                            <v-menu activator="parent" location="left" offset="10px">
                                <v-list>
                                    <v-list-item 
                                    v-for="item in languages" 
                                    :link="true" @click="setLanguage(item)"   
                                    :active="vxLanguage.name == item.name"
                                    >{{ item.name }}</v-list-item>                                                                        
                                </v-list>
                            </v-menu>
                        </v-list-item>
                        <v-list-item :link="true" @click="openPrivacyTab">
                            <div class="d-flex align-items gap-2">
                                <v-icon icon="mdi-shield-account-outline"></v-icon>
                                <v-list-item-title>{{ t('privacy') }}</v-list-item-title>                                
                            </div>                        
                        </v-list-item>
                        <v-list-item :link="true" @click="openImprintTab">
                            <div class="d-flex align-items gap-2">
                                <v-icon icon="mdi-copyright"></v-icon>
                                <v-list-item-title>{{ t('imprint') }}</v-list-item-title>                                
                            </div>                        
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-btn>        
            <v-dialog v-model="logoutDialog" width="auto" min-width="300px" max-width="600px">
                <v-card>
                    <v-card-title class="d-flex align-center"><v-icon size="small" icon="mdi-logout" class="mr-1"></v-icon>Ausloggen</v-card-title>
                    <v-card-text>
                        {{ t('logout-dialog') }}
                        <v-text-field hide-details readonly append-inner-icon="mdi-content-copy" variant="outlined" @click:append-inner="copyText" density="compact" class="mt-4" :value="user.surveyCode" :label="t('survey-code')" dirty></v-text-field>
                        <v-snackbar v-model="copySnackbar">{{ t('copy-message') }}</v-snackbar>
                    </v-card-text>                                    
                    <v-card-actions class="d-flex justify-end">
                        <v-btn @click="logoutDialog = false">{{t('cancel')}}</v-btn>
                        <v-btn @click="handleLogout">{{ t('alright') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog> 
        </v-app-bar>
        <v-main>
            <router-view v-slot="{ Component }" v-if="ready">
                <v-slide-x-transition leave-absolute hide-on-leave>
                    <component 
                        :is="Component" 
                        v-bind="routerComponentProps" 
                        @login="handleLogin"
                        @finish="handleFinishSurvey"
                    ></component>
                </v-slide-x-transition>
            </router-view>
            <div v-else class="d-flex justify-center align-center w-100 h-100">
                <v-progress-circular indeterminate="true" size="45"></v-progress-circular>
            </div>
        </v-main>
        <v-snackbar :timeout="-1" v-model="snackbar.show" :color="snackbar.color">
            {{ snackbar.message }}
            <template v-slot:actions>
                <v-btn icon="mdi-close" @click="snackbar.show = false"></v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>
<script setup>
import { computed, ref, reactive, watch, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';

const store = useStore();
const vxLanguage = computed(()=>{return store.state.language});
const vxLanguages = computed(() => {return store.state.languages});
const { t } = useI18n({useScope: 'local'});
const { locale, availableLocales } = useI18n({useScope: 'global'});
const setLanguage = language => {
    store.state.language = language;
}
const languages = ref([]);

const ready = ref(false);
const snackbar = ref({
    show: false,
    message: '',
    color: 'secondary',
});
const copySnackbar = ref(false);
const logoutDialog = ref(false);

const user = ref({
    authenticated: false,
    surveyCode: ''
});

const openImprintTab = ()=>{
    window.open('https://www.ecoprotec.de/impressum/', '_blank');
}
const openPrivacyTab = ()=>{
    window.open('https://www.ecoprotec.de/datenschutz-infos/', '_blank');
}

const route = useRoute();
const router = useRouter();
const navigateToHome = ()=>{
    router.push({name: 'home'});
}

//watch(user, function(newValue){console.log('USER', newValue)}, {deep:true})

const routerComponentProps = computed(function(){
    if (route.name == 'survey') {
        return {authenticated: user.value.authenticated}
    }
    return {};
});

const notifyUser = function(message = '', color = 'secondary', keep = false, timeout = 2500){
    snackbar.value.message = message;
    snackbar.value.color = color;
    snackbar.value.show = true;
    if(keep)return
    setTimeout(()=>{
        snackbar.value.show = false;
    }, timeout)
}

const sendAuthenticationCheckRequest = function(){
    axios.get('/participant/authentification')
    .then(response => {
        if (!response.data.error.has) {
            // ready.value = true
            //console.log('eingeloggt als teilnehmer')
            user.value.authenticated = response.data.data.authenticated;
            user.value.surveyCode = response.data.data.user.access_code;
            //console.log('USEr in F', user.value)
            redirectAuthenticatedUser();
        }
        else{
            redirectUnauthenticatedUser();
            // ready.value = true;
            //console.log('Nutzer nicht angemeldet')
        }
        setReady();
        // ready.value = true
        //console.log('in Authcheck method\n', new Date().getMilliseconds())
    })
    // .then()
}

const setReady = function(){
    nextTick(function(){
        ready.value = true;
    })
}

const handleFinishSurvey = function(){
    sendLogoutRequest().finally(()=>{
        user.value.surveyCode = '';
    });
}

const sendLogoutRequest = async function(){
    var response = await axios.post('/participant/logout')
    return response;
}

const redirectUnauthenticatedUser = function(){
    if (route.name === 'survey') {
        router.push({name: 'login'})
    }
}

const redirectAuthenticatedUser = function(){
    if(route.name === 'login' || route.name === 'home'){
        router.push({name: 'survey'});
    }
}

const handleLogin = function(response){
    //console.log('response', response)
    user.value.authenticated = true;
    user.value.surveyCode = response.data.user.access_code;
}

const handleLogoutError = function(){
    notifyUser(t('error.logout'), 'red', true);
}

const handleLogout = function(){
    logoutDialog.value = false;
    sendLogoutRequest()
    .then(rsp => {
        if (rsp.data.error.has) {
            handleLogoutError();
            return;
        }
        notifyUser(t('logout-message'));
        user.value.surveyCode = '';
        user.value.authenticated = false;
        redirectUnauthenticatedUser();
    })
    .catch(error => {
        handleLogoutError();
    });
}

const copyText = async () => {
    try {
      await navigator.clipboard.writeText(user.value.surveyCode);
      copySnackbar.value = true;
      setTimeout(()=>{copySnackbar.value = false}, 1800)
      //console.log('Content copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

const checkURLForSurveyCode = function(){
    if (route.params.code != undefined && route.params.code != null && route.params.code != '') {
        var code = route.params.code;
        if (code.length == 14) {
            router.push({name: 'login', params: {code: code}})
        }
        else{
            router.replace('/');
        }
        setReady();
    }
    else{
        sendAuthenticationCheckRequest();
    }
}

const RouteParamsWatcher = watch(()=>route.params, ()=>{
    checkURLForSurveyCode();
})

watch(vxLanguages, value => {
    languages.value = value;
}, {immediate: true, deep: true});

watch(vxLanguage, value => {
    const searchResult = availableLocales.find(locale => locale === value.language_code);
    if (searchResult === undefined) {
        locale.value = 'de';
    }
    else{
        locale.value = value.language_code;
    }
})

onMounted(()=>{
    // check if code in url

});
</script>

<i18n>
{
    "de": {
        "logout": "Ausloggen",
        "language": "Sprache",
        "privacy": "Datenschutz",
        "imprint": "Impressum",
        "survey-code": "Zugangscode",
        "copy-message": "Dein Zugangscode wurde in die Zwischenablage kopiert!",
        "cancel": "Abbrechen",
        "alright": "Alles klar",
        "logout-dialog": "Du hast die Befragung noch nicht abgeschlossen, aber kein Problem! Mit deinem persönlichen Zugangscode kannst du jederzeit weitermachen:",
        "logout-message": "Nutzer abgemeldet",
        "error": {
            'logout': "Beim Ausloggen ist ein Fehler aufgetreten"
        }
    },
    "en": {
        "logout": "Logout",
        "language": "Language",
        "privacy": "Privacy",
        "imprint": "Imprint",
        "survey-code": "Survey Code",
        "copy-message": "Access code copied to clipboard!",
        "cancel": "Cancel",
        "alright": "Alright",
        "logout-dialog": "You haven't finished the survey yet, not a problem! You can continue anytime with your personal access code:",
        "logout-message": "User was logged out",
        "error": {
            'logout': "Unexpected error while logging out"
        }
    }
}
</i18n>

<style>
</style>