import viewHome from './views/guest/home.vue'
import surveyIntro from './views/guest/survey-intro.vue'
import surveyQuestions from './views/guest/survey-questions.vue'
import surveyOutro from './views/guest/survey-outro.vue'
import surveyFinished from './views/guest/survey-finished.vue'

import participantHome from './modules/participant/home.vue';
import participantLogin from './modules/participant/login.vue';
import survey from './modules/participant/survey.vue'

export default [
    {
        name: 'home',
        path: '/:code?',
        component: participantHome,
        alias: '/'
    },
    {
        name: 'login',
        path: '/anmeldung/:code?',
        component: participantLogin
    },
    {
        name: 'survey',
        path: '/survey',
        component: survey
    }
    // {
    //     name: 'survey-intro',
    //     path: '/survey/intro',
    //     component: surveyIntro
    // },
    // {
    //     name: 'survey-questions',
    //     path: '/survey/questions',
    //     component: surveyQuestions
    // },
    // {
    //     name: 'survey-outro',
    //     path: '/survey/outro',
    //     component: surveyOutro
    // },
    // {
    //     name: 'survey-finished',
    //     path: '/survey/finished',
    //     component: surveyFinished
    // }
]