<template>
    <v-card class="" max-width="800px">
        <div class="d-flex">
            <div class="d-flex justify-center align-center bg-primary pa-4">
                <v-icon color="secondary" :size="iconSize" icon="mdi-thumb-up"></v-icon>
            </div>
            <div class="text-subtitle-2 text-sm-h6 font-weight-regular pa-4 d-flex align-center">{{ t('text') }}</div>
        </div>
    </v-card>
</template>

<script setup>
import { useDisplay } from 'vuetify';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const {smAndUp, mdAndUp} = useDisplay();
const { t } = useI18n({useScope: 'local'});
const iconSize = computed(function(){

    if (mdAndUp.value) 
    return '60';
    if (smAndUp.value) 
    return '40';

    return '24';
})

const emit = defineEmits(['timeout']);

onMounted(function(){
    setTimeout(()=>{
        emit('timeout');
    }, 3000)
})

</script>

<i18n>
{
    'de': {
        'text': 'Du hast die Befragung erfolgreich abgeschlossen und kannst dieses Fenster nun schließen.'
    },
    'en': {
        'text': 'You finished the survey successfully and may close this window now.'
    }
}
</i18n>