<template>
    <div class="d-flex w-100 h-100 max-h-100 justify-center align-center bg-grey-lighten-5 gap-3 align-stretch px-3 px-sm-6 px-md-10 py-md-10 py-4 relative">
    <!-- Participate Button -->
    <v-btn v-if="display.mdAndUp" prepend-icon="mdi-login" class="mr-8 mt-6 right-0" position="fixed" color="primary" size="large" to="/anmeldung">Zur Teilnahme</v-btn>
        <div class="d-flex flex-column justify-center align-center gap-4 mb-0 mb-md-16">
            <!-- Heading -->
            <div class="d-flex flex-column align-center mb-4 mb-sm-8 mb-md-12 text-center">
                <div class="d-flex text-h2 text-sm-h1">
                    <div class="font-weight-bold text-primary">{{ t('heading.eco') }}</div>
                    <div class="font-weight-bold">{{ t('heading.ask') }}</div>
                </div>
                <v-divider width="100%"></v-divider>
                <div class="ml-2 text-h5 text-sm-h4 font-weight-regular text-secondary">{{ t('subheading') }}</div>                        
            </div>
            <!-- Quote -->
            <div class="d-flex flex-column align-center align-sm-end mb-4 mb-sm-8 mb-md-12">
                <div class="text-h6 text-sm-h5 text-md-h4 font-weight-light text-center text-sm-left">„{{ $t('quotes.nietzsche.quote') }}“</div>
                <div class="font-italic">{{ $t('quotes.nietzsche.author') }}</div>
            </div>
            <!-- Ad Text -->
            <div class="text-justify text-subtitle-1 text-sm-h6 font-weight-regular line-height-2 line-height-xs-3 line-height-sm-4" style="max-width: 1000px">
                <p class="">{{t('text.p1')}}</p><br>
                <p class="">{{t('text.p2')}}</p><br>
                <p class="">{{t('text.p3')}}</p><br>
                <p class="">{{t('text.p4')}}</p><br>
                <p class="">{{t('text.p5')}}</p>
            </div>
            <!-- Particpate Button on small screens -->
            <v-btn v-if="display.smAndDown" prepend-icon="mdi-login" class="align-self-end mt-4" color="primary" :size="display.mdAndUp ? 'large' : 'default'" to="/anmeldung">{{ t('participate-button') }}</v-btn>
        </div>                    
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({useScope: 'local'});

const {smAndUp, mdAndUp, lgAndUp, xlAndUp, mdAndDown, smAndDown} = useDisplay();
const display = computed(()=>{
    return {
        smAndUp: smAndUp.value,
        smAndDown: smAndDown.value,
        mdAndUp: mdAndUp.value,
        mdAndDown: mdAndDown.value,
        lgAndUp: lgAndUp.value,
        xlAndUp: xlAndUp.value
    }
});
</script>
<i18n>
{
    'de': {
        "heading": {
            "eco": "eco",
            "ask": "Ask"
        },
        "subheading": "ecoprotec Befragungen",
        "participate-button": "Zur Teilnahme",
        "quote": {
            "author": "Friedrich Nietzsche",
            "text": "Dem guten Frager ist schon halb geantwortet."
        },
        "text": {
            "p1": "Unser Tool „ecoAsk“ eignet sich hervorragend, um verschiedene Themen zu hinterfragen. Durch die Antworten der Befragten können bestimmte Maßnahmen abgeleitet werden. Sie möchten beispielsweise die Zufriedenheit Ihrer Mitarbeiter abfragen, erfahren, ob und wie die Mitarbeiter im Homeoffice zurechtkommen oder eine Führungskraft bewerten?",
            "p2": "Hierbei kann ecoAsk Sie schnell und einfach unterstützen. Neben der Befragung der passenden Zielgruppe, ist die Auswahl und der Einsatz geeigneter Methoden essenziell für ein erfolgreiches Ergebnis. Auf Basis unserer Erfahrung kombinieren wir unterschiedlichste Erhebungsmethoden, von der anonymen Umfrage bis hin zur persönlichen telefonischen Befragung und Beratung.",
            "p3": "Nachdem Sie Ihren persönlichen Zugangs-Code eingegeben haben, gelangen Sie zu Ihrer vorbereiteten Befragung.",
            "p4": "Wir bedanken uns schon jetzt für Ihre Teilnahme.",
            "p5": "Ihre ecoprotec GmbH"
        }
    },
    'en': {
        "heading": {
            "eco": "eco",
            "ask": "Ask"
        },
        "subheading": "ecoprotec Surveys" ,
        "participate-button": "To Login",
        "quote": {
            "author": "Friedrich Nietzsche",
            "text": "The good questioner is already half answered."
        },
        "text": {
            "p1": "Our tool „ecoAsk“ is perfectly suited, to question various types of topics. By evaluating the participants' answers, appropriate measures can be derived. Would you like to determine how happy your employees are with their jobs, find out how they deal with working remote or get anonymous evaluations of your executives?",
            "p2": "ecoAsk can easily support you doing exactly that. In addition to interviewing the appropriate target group, the selection and use of suitable methods is essential for a successful result. Based on our experiences we are able to combine various survey methods, such as anonymous surveys, personal telephone surveys and consultation.",
            "p3": "After you have entered your personal access code, you will be taken to your prepared survey.",
            "p4": "We thank you in advance for your participation.",
            "p5": "Your ecoprotec GmbH" 
        }
    }
}
</i18n>